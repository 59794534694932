import request from '@/utils/request'

// 验证是否登录或者登录是否过期
export function validateLogin() {
  return request({
    url: '/User/validateLogin'
  })
}

// 验证码
export function getVierificationCode() {
  return request({
    url: '/User/getVierificationCode'
  })
}
// 登录
export function login(data) {
  return request({
    url: '/User/login',
    method: 'post',
    data
  })
}
// 获取用户列表
export function getPageData(data) {
  return request({
    url: '/Sys_User/getPageData',
    method: 'post',
    data
  })
}

// 上传头像
export function uploadAvatar(data) {
  return request({
    url: '/Sys_User/Upload',
    method: 'post'
  })
}

// 用户注册
export function registerUser(data) {
  return request({
    url: '/User/register_User',
    method: 'post',
    data
  })
}

// 更新用户信息
export function update(data) {
  return request({
    url: '/Sys_User/update',
    method: 'post',
    data
  })
}

// 获取登录用户信息
export function getCurrentUserInfo() {
  return request({
    url: '/Sys_User/getCurrentUserInfo',
    method: 'post'
  })
}

// 找回密码
export function ModifyPwdOnly(data) {
  return request({
    url: '/User/ModifyPwdOnly',
    method: 'post',
    params: data
  })
}

export default {
  login,
  getVierificationCode,
  getPageData,
  uploadAvatar,
  update,
  getCurrentUserInfo
}
