<template>
  <div :class="['m-head', {
      'm-head--fixed': fixed
    }]">
    <div class="wrap m-w1200">
      <div class="m-head-l">
        <div class="logo"></div>
        <h1 class="site-name">{{ setting.siteTitle }}</h1>
      </div>
      <div class="m-head-r">
        <ul class="nav-menu">
          <li><a href="#/home">{{$t('page.home.name')}}</a></li>
          <!-- <li><a href="#/search">{{$t('page.search.name')}}</a></li> -->
          <li><a href="#/sample">{{$t('page.sample.name')}}</a></li>
          <li><a href="#/visualized">{{$t('page.visualized.name')}}</a></li>
          <li><a href="#/reserve">{{$t('page.reserve.name')}}</a></li>
          <!-- <li><a href="#/journal">{{$t('page.journal.name')}}</a></li> -->
          <li><a href="#/news">{{$t('page.news.name')}}</a></li>
          <li><a href="#/about-us">{{$t('page.aboutus.name')}}</a></li>
        </ul>
        <avatar></avatar>
        <lang-set theme="plain"></lang-set>
      </div>
    </div>
  </div>
</template>

<script>
import setting from '@/setting'
import Avatar from './avatar'
import LangSet from './lang-set'
export default {
  components: {
    LangSet,
    Avatar
  },
  data() {
    return {
      fixed: false,
      setting
    }
  },
  methods: {
    scrollListener() {
      let that = this
      let el_main = document.querySelector('.m-main')
      let onscroll = function() {
        var scrollTop = el_main.scrollTop
        if (scrollTop >= 100) {
          that.fixed = true
        } else {
          that.fixed = false
        }
      }
      el_main.addEventListener('scroll', onscroll)
      this.$once('hook:beforeDestory', function() {
        el_main.removeEventListener('scroll', onscroll)
      })
    }
  },
  mounted() {
    this.scrollListener()
  }
}
</script>
<style lang="less" scoped>
  @keyframes slidein {
    from {
      opacity: 0;
      height: 0;
    }
    to {
      opacity: 1;
      height: 60px;
    }
  }
  .m-head {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 60px;
    flex-grow: 0;
    z-index: 999;
    transition: all .2s linear;
    background-color: #fff;
    .wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      height: 100%;
    }
    &-l {
      display: flex;
      align-items: center;
      .logo {
        margin-right: 18px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-image: url('../../images/logo.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 40px;
        transition: all .2s ease;
        transition-delay: .4s;
      }
      .site-name {
        font-weight: 500;
        font-size: 22px;
        color: #000000;
        line-height: 26px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

    }

    &-r {
      display: flex;
      justify-content: center;
      align-items: center;
      .nav-menu {
        margin-right: 20px;
        display: flex;
        & > li {
          margin-right: 15px;
          & > a {
            padding: 8px 14px;
            font-weight: bold;
            font-size: 16px;
            color: #000;
            line-height: 19px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            text-decoration: none;
            border-radius: 3px;
            transition: all .2s ease;
            &:last-child {
              margin-right: 0;
            }
            &:hover {
              background-color: #1A76D1;
              color: #fff;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .lang {
        float: right;
        margin-top: 28px;
        margin-left: 30px;
        li {
          float: left;
          margin-right: 10px;
          padding: 0 0;
          font-size: 12px;
          font-weight: bold;
          line-height: 20px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          &.on {
            border-bottom: 3px solid #fff;
          }
        }
      }
    }

    &--fixed {
      position: fixed;
      height: 60px;
      animation: slidein .2s linear;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
      .logowrap {
        height: 60px;
        box-shadow: none;
        // background-color: rgba(255,255,255,.95);
        .logo {
          // bottom: 5px;
        }
      }
      .nav-menu {
        line-height: 60px;
        li > a {
          // font-weight: normal;
        }
      }
    }
  }
</style>
