<template>
  <div class="m-main">
    <main-head></main-head>
    <div class="m-body">
      <router-view></router-view>
    </div>

    <!-- <login-dialog></login-dialog> -->
  </div>
</template>

<script>
import MainHead from './child/head'
import MainFoot from './child/foot'
// import LoginDialog from '@/components/login-dialog'
export default {
  components: {
    MainHead,
    MainFoot
  },
  data() {
    return {

    }
  },
  methods: {
    onLoginDialogClose() {
      this.loginDialogShowSet(false)
    }
  }
}
</script>

<style lang="less" scoped>
  .m-main {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    overflow: auto;
    background-color: #f8f8f8;
  }
  .m-body {
    flex-grow: 1;
  }
</style>
