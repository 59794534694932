<template>
  <div class="item">
    <div
      class="item-img"
      :style="{'background-image': `url('${model.ex_ImageUrl}')`}">
    </div>
    <div class="item-r">
      <div class="item-r-top">
        <h3 class="item-title">{{ model.Title }}</h3>
        <div class="item-des">
          <p>{{ model.Content | summary(113) }}</p>
        </div>
      </div>
      <div class="item-r-bottom">
        <div class="item-date">
          <i class="el-icon-time"></i>
          {{ model.CreateDate }}
        </div>
        <div class="item-action">
          <el-button type="primary" @click="onDetail(model.DetailUrl)">
            {{$t('action.detail')}}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({})
    }
  },
  filters: {
    summary: function(val, maxLength=100, replaceChar='...') {
      if (!val) {return ''}
      let str = val.replace(/<.*?>|&nbsp;|&emsp;/g,"")
      if (str.length > maxLength) {
        str = str.substr(0, maxLength) + replaceChar
      }
      return str
    }
  },
  methods: {
    onDetail(detailUrl) {
      this.$router.push(`/news/detail?detailUrl=${detailUrl}`)
    }
  }
}
</script>

<style lang="less" scoped>
.item {
  display: flex;
  padding: 30px;
  background-color: #fff;
  &-img {
    flex: 0 0 304px;
    margin-right: 30px;
    height: 240px;
    background-position: center;
    background-size: cover;
  }
  &-r {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-top {
      .item-title {
        margin-bottom: 15px;
        font-weight: 500;
        font-size: 22px;
        color: #000000;
        line-height: 26px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .item-des {
        font-weight: 400;
        font-size: 16px;
        color: #737380;
        line-height: 32px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
    &-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item-date {
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }
}
</style>