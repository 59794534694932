import axios from 'axios'
import { Notification } from 'element-ui'
import authUtil from '@/utils/auth'
import setting from '@/setting'
import store from '@/store'
import router from '@/router'

// 创建axios实例
const service = axios.create({
  //baseUrl: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/', // api 的 base_url
  baseURL: setting.baseUrl.api ,
  timeout: setting.timeout // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    if (!config.headers.Authorization) {
      let token = authUtil.tokenGet()
      config.headers['Authorization'] = 'Bearer ' + token || ''
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const code = response.status
    if (code < 200 || code > 300) {
      Notification.error({
        title: response.message
      })
      return Promise.reject('error')
    } else {
      // 如果是二进制文件处理
      if (response.data instanceof Blob) {
        downloadFile(response)
      }
      return response.data
    }
  },
  error => {
    let code = 0
    try {
      code = error.response.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        Notification.error({
          title: '网络请求超时',
          duration: 5000
        })
        return Promise.reject(error)
      }
    }
    if (code) {
      if (code === 401) {
        // 未登录
        loginOut();
      } else if (code === 403) {
        // 登录超时
        loginOut();
      } else {
        const errorMsg = error.response.data.message
        if (errorMsg !== undefined) {
          Notification.error({
            title: errorMsg,
            duration: 5000
          })
        }
      }
    } else {
      Notification.error({
        title: '接口请求失败',
        duration: 5000
      })
    }
    return Promise.reject(error)
  }
)

// 下载文件
function downloadFile(res) {
  if (!res || !res.data) {
    return;
  }
  let { data, headers } = res;
  let filename = '';
  let dispArr = headers['content-disposition'].split(';').map(val => val.trim());
  dispArr.forEach(val => {
    if(val.startsWith('filename') && !filename) {
      filename = val.split('=')[1];
    }
  });
  filename = decodeURI(escape(filename)); // 中文乱码处理
  let blob = new Blob([data], { type: headers['content-type'] });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
function loginOut() {
  let redirect = router.app.$route.fullPath;
  router.push(`/auth?type=login&redirect=${redirect}`)
  store.dispatch('logout')
}
export default service
