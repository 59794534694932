var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['section', {
    'section--full-width': _vm.fullWidth
  }],style:(_setup.sty)},[_c('div',{staticClass:"section-wrap"},[(_vm.title)?_c('div',{staticClass:"section-head"},[_c('span',{staticClass:"title",style:({
          color: _vm.titleColor
        })},[_vm._v(" "+_vm._s(_vm.title)+" ")])]):_vm._e(),_c('div',{staticClass:"section-body"},[_vm._t("default")],2),(_vm.showMore)?_c('div',{staticClass:"section-foot"},[_c('div',{class:['more', {
          'more--border': _vm.showMoreBorder
        }]},[(_vm.moreUrl)?_c('router-link',{attrs:{"to":_vm.moreUrl}},[_vm._v("更多")]):_c('a',[_vm._v("更多")])],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }