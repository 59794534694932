import Vue from 'vue'

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import "tui-calendar/dist/tui-calendar.css"
import i18n from '@/lang'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/less/app.less'
import GlobalCom from '@/components/globalCom.js'
import App from './app.vue'
import router from './router'
import store from './store'
import '@/filter'
import drag from "v-drag"
Vue.use(GlobalCom)
Vue.use(drag, {
  // global configuration
});
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})

Vue.use(Element, {
  zIndex: 3000,
  i18n: (key, value) => i18n.t(key, value)
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
