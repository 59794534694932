import Vue from 'vue'
import VueRouter from 'vue-router'
import LayMain from '@/layout/main/Main.vue'
import LayMainNoFoot from '@/layout/main/MainNoFoot.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: LayMainNoFoot,
    children: [
      {
        path: '/visualized',
        component: () => import('@/views/visualized')
      },
      {
        path: '/visualized/data-analysis',
        component: () => import('@/views/visualized/DataAnalysis/index.vue')
      }
    ]
  },
  {
    path: '/',
    redirect: '/home',
    component: LayMain,
    children: [
      {
        path: '/home',
        component: () => import('@/views/home')
      },
      {
        path: '/search',
        redirect: '/search/composition',
        component: () => import('@/views/search'),
        children: [
          {
            path: 'keyword',
            component: () => import('@/views/search/child/keyword')
          },
          {
            path: 'topic',
            component: () => import('@/views/search/child/topic')
          },
          {
            path: 'composition',
            component: () => import('@/views/search/child/composition')
          }
        ]
      },
      {
        name: 'search_result',
        path: '/search/result',
        component: () => import('@/views/search/result')
      },
      {
        path: '/sample',
        redirect: '/sample/add',
        component: () => import('@/views/sample'),
        children: [
          {
            path: 'add',
            component: () => import('@/views/sample/child/add')
          },
          {
            path: 'import-standard',
            component: () => import('@/views/sample/child/importStandard')
          },
          {
            path: 'import-non-standard',
            component: () => import('@/views/sample/child/importNonStandard')
          }
        ]
      },
      {
        name: 'ancient-maps',
        path: '/ancient-maps',
        component: () => import('@/views/visualized/AncientMap.vue')
      },
      {
        name: 'search_visualizedResult',
        path: '/search/visualized-result',
        component: () => import('@/views/search/visualized-result')
      },
      {
        name: 'search_result_detail',
        path: '/search/result-detail',
        component: () => import('@/views/search/result-detail'),
        redirect: '/search/result-detail/sample',
        children: [
          {
            path: 'sample',
            component: () => import('@/views/search/result-detail/sample')
          },
          {
            path: 'micronutrient',
            component: () => import('@/views/search/result-detail/micronutrient')
          },
          {
            path: 'principal-element',
            component: () => import('@/views/search/result-detail/principal-element')
          },
          {
            path: 'otopes',
            component: () => import('@/views/search/result-detail/otopes')
          },
          {
            path: 'ICPMS',
            component: () => import('@/views/search/result-detail/ICPMS')
          }
        ]
      },

      {
        path: '/reserve',
        component: () => import('@/views/reserve')
      },
      {
        path: '/reserve/detail',
        component: () => import('@/views/reserve/detail')
      },
      {
        path: '/reserve/apply',
        component: () => import('@/views/reserve/apply')
      },
      {
        path: '/journal',
        component: () => import('@/views/journal')
      },
      {
        path: '/news',
        component: () => import('@/views/news')
      },
      {
        path: '/news/research',
        component: () => import('@/views/news/research-news')
      },
      {
        path: '/news/detail',
        component: () => import('@/views/news/detail')
      },
      {
        path: '/about-us',
        redirect: '/about-us/intro',
        component: () => import('@/views/about-us'),
        children: [
          {
            path: 'intro',
            component: () => import('@/views/about-us/child/intro')
          },
          {
            path: 'concat',
            component: () => import('@/views/about-us/child/concat')
          }
        ]
      },
      {
        path: '/personal',
        redirect: '/personal/setting',
        component: () => import('@/views/personal'),
        children: [
          {
            path: 'setting',
            component: () => import('@/views/personal/child/setting')
          }
        ]
      },
      {
        path: '/personal/reserve',
        component: () => import('@/views/personal/reserve')
      },
      {
        path: '/personal/data',
        component: () => import('@/views/personal/data')
      }
    ]
  },
  {
    path: '/auth',
    component: () => import('@/views/auth')
  },
  {
    path: '/demo',
    component: () => import('@/views/demo')
  }

]

const router = new VueRouter({
  routes
})

export default router
