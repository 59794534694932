<template>
  <div id="app" v-if="!appForceUpdate">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as userApi from '@/api/user'
export default {
  computed: {
    ...mapState([
      'appForceUpdate'
    ])
  },
  provide: function () {
    return {
      validateLogin() {
        userApi.validateLogin()
      }
    }
  }
}
</script>

<style lang="less">
#app {
  height: 100%;
}
</style>
