<!--
 * @Author: lg9527 13691080728@126.com
 * @Date: 2023-05-10 21:32:31
 * @LastEditors: lg9527 13691080728@126.com
 * @LastEditTime: 2023-11-16 16:36:10
 * @FilePath: \zky_webVue\src\views\main\child\foot.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="m-foot">
    <div class="main m-w1200">
      <div class="main-l">
        <div class="logo">
          <img src="../images/logo-parent.png" />
        </div>
        <ul class="info">
          <li>{{$t('m_foot.address')}}：{{$t('m_foot.addressVal')}}</li>
          <li>{{$t('m_foot.postalCode')}}：<span class="h-num">100029</span></li>
          <li>{{$t('m_foot.tel')}}：<span class="h-num">010-82998418</span></li>
          <li>{{$t('m_foot.fax')}}：<span class="h-num">010-62010846</span></li>
        </ul>
      </div>
      <div class="icon"></div>
    </div>
    <div class="copyright">
      版权所有 ©2009-2024 中国科学院地质与地球物理研究所
      <a href="https://beian.miit.gov.cn">京ICP备05029136号-26</a>    
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
  .m-foot {
    flex-grow: 0;
    line-height: 60px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    // color: #fff;
    .h-num {
      font-size: 13px;
    }
    .main {
      display: flex;
      align-items: center;
      padding: 60px 0;
      &-l {
        flex: 1;
        display: flex;
        flex-direction: column;
        .logo {
          margin-bottom: 47px;
          height: 55px;
          img {
            height: 100%;
          }
        }
        .info {
          display: flex;
          gap: 61px;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          line-height: 19px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }
      .icon {
        flex: 0 0 50px;
        height: 57px;
        background-image: url('../images/icon-sydw.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 71px;
      }
    }
    .copyright {
      padding: 20px 0;
      color: #fff;
      background-color: #171d24;
      font-size: 14px;
      line-height: 1em;
      text-align: center;
      border-top: 1px solid #eee;
      a {color: #409EFF; text-decoration: none;}
    }

    &--theme-dark {
      background-color: #171d24;
      color: #fff
    }
  }
</style>
