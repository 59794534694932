import Section from "./basic/Section.vue"
import NewList from "./basic/NewList.vue"
import NewListItem from "./basic/NewListItem.vue"
import PageCover from './pageCover/Index.vue'

export default {
  install: (Vue, options) => {
    Vue.component("Section", Section)
    Vue.component("NewList", NewList)
    Vue.component("NewListItem", NewListItem)
    Vue.component("PageCover", PageCover)
  }
}