import Vue from 'vue'
import Vuex from 'vuex'
import authUtil from '@/utils/auth'
import userApi from '@/api/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 登录框是否显示
    loginDialogShow: false,
    token: authUtil.tokenGet(),
    user: authUtil.userGet(),
    // 语言
    lang: localStorage.getItem('lang') || 'zh-CN',
    // app刷新 供整体重新渲染使用
    appForceUpdate: false
  },
  mutations: {
    loginDialogShowSet: (state, isShow) => {
      state.loginDialogShow = isShow
    },
    tokenSet: (state, token) => {
      state.token = token
      authUtil.tokenSet(token)
    },
    userSet: (state, user) => {
      state.user = user
      authUtil.userSet(user)
    },
    /**
     * 站点语言写入
     * @param {*} state
     * @param {*} data
     */
    langSet(state, data) {
      state.lang = data
      localStorage.setItem('lang', data)
    },
    /**
     * 站点重新渲染
     * @param {*} state
     */
    appForceUpdateDo(state) {
      state.appForceUpdate = true
      Vue.nextTick(() => {
        state.appForceUpdate = false
      })
    },
  },
  actions: {
    logout: ({commit}, data) => {
      commit('tokenSet', '')
      commit('userSet', null)
      // 是否强制刷新
      data.isForceUpdate && commit('appForceUpdateDo')
    },
    getCurrentUserInfo: ({commit}) => {
      return new Promise((resolve, reject) => {
        userApi.getCurrentUserInfo().then(res => {
          if(!res.status || !res.data) {
            reject('error')
            return
          }
          commit('userSet', res.data)
          resolve(res.data)
        })
      })
    }
  },
  modules: {
  }
})
