<template>
  <div :class="['zm-select', {
      'zm-select--open': open,
      'zm-select--theme-plain': theme === 'plain'
    }]">
    <div class="zm-select_trigger" @click="onToogleOpen">
      <span class="zm-select_val">{{curLabel}}</span>
      <span class="zm-select_icon el-icon-caret-bottom"></span>
    </div>
    <transition name="el-zoom-in-top">
      <div v-show="open" class="zm-select_options-wrap">
        <ul class="zm-select_options">
          <li
            v-for="item in options"
            :class="{'on': val === item.val}"
            @click="onOptionClick($event, item)">
            {{item.label}}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  props: {
    theme: {
      type: String,
      default: 'light'
    }
  },
  data() {
    return {
      open: false,
      val: 'zh-CN',
      options: [
        {label: '中文', val: 'zh-CN'},
        {label: 'EN', val: 'us-EN'}
      ]
    }
  },
  computed: {
    ...mapState([
      'lang'
    ]),
    curLabel() {
      let model = this.options.find(item => item.val === this.val)
      return model.label
    }
  },
  watch: {
    lang: {
      immediate: true,
      handler: function(val) {
        this.val = val
      }
    }
  },
  methods: {
    ...mapMutations([
      'langSet',
      'appForceUpdateDo'
    ]),
    onToogleOpen(e) {
      e.stopPropagation()
      this.open = !this.open
    },
    onOptionClick(e, item) {
      e.stopPropagation()
      this.val = item.val
      this.close()
      this.langSet(item.val);
      // 此处延迟纯粹为了操作感觉 无其他用意
      setTimeout(() => {
        this.locale = this.$i18n.locale = item.val
        this.appForceUpdateDo()
      }, 300);
    },
    close() {
      this.open = false
    },
    _eventInit() {
      document.addEventListener('click', this.close)
      this.$once('hook:beforeDestroy', function() {
        document.removeEventListener('click', this.close)
      })
    }
  },
  mounted() {
    this._eventInit()
  }
};
</script>

<style lang="less" scoped>
.zm-select {
  display: inline-block;
  position: relative;
  &_trigger {
    // padding: 0 20px;
    position: relative;
    top: -2px;
    margin-left: 20px;
    line-height: 40px;
    font-size: 14px;
    cursor: pointer;
  }
  &_icon {
    display: inline-block;
    margin-left: 2px;
    transition: transform .2s linear;
  }
  &_options-wrap {
    position: absolute;
    top: 100%;
    right: 0;
    padding-top: 10px;
  }
  &_options {
    position: relative;
    padding: 10px 0;
    min-width: 90px;
    white-space: nowrap;
    font-size: 14px;
    border: 1px solid #eee;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    li {
      padding: 0 20px;
      line-height: 36px;
      cursor: pointer;
      transition: all .2s linear;
      &:hover {
        background-color: #eee;
      }
      &.on {
        background-color: #eee;
        font-weight: bold;
      }
    }
    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      right: 20px;
      border: 6px solid transparent;
      border-bottom-color: #eee;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      right: 21px;
      border: 5px solid transparent;
      border-bottom-color: #fff;
    }
  }

  &--open {
    .zm-select_icon {
      transform: rotate(180deg);
    }
  }
  &--theme-plain {
    .zm-select_trigger {
    }
  }
}
</style>
