<template>
  <el-dropdown trigger="click" @command="onCommand">
    <span class="el-dropdown-link">
      <el-avatar
        class="avatar"
        icon="el-icon-user-solid"
        size="small"
        :src="avatarImg">
      </el-avatar>
    </span>
    <el-dropdown-menu slot="dropdown">
      <div class="info">
        <div v-if="user" class="info_name">{{user.userTrueName}}</div>
        <div v-else class="info_name">{{$t('auth.message.unLogin')}}</div>
      </div>
      <template v-if="user">
        <el-dropdown-item command="my-data">
          <i class="el-icon-s-order"></i>
          {{$t('auth.action.data')}}
        </el-dropdown-item>
        <el-dropdown-item command="my-reserve">
          <i class="el-icon-message-solid"></i>
          {{$t('auth.action.reserve')}}
        </el-dropdown-item>
        <el-dropdown-item command="setting">
          <i class="el-icon-user-solid"></i>
          {{$t('auth.action.setting')}}
        </el-dropdown-item>
        <el-dropdown-item command="logout">
          <i class="el-icon-s-promotion"></i>
          {{$t('auth.action.logout')}}
        </el-dropdown-item>
      </template>
      <template v-else>
        <el-dropdown-item command="login">{{$t('auth.action.login')}}</el-dropdown-item>
      <el-dropdown-item command="register">{{$t('auth.action.register')}}</el-dropdown-item>
      </template>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import setting from '@/setting'

export default {
  computed: {
    ...mapState([
      'user'
    ]),
    avatarImg() {
      if (this.user && this.user.headImageUrl) {
        return setting.baseUrl.img + '/' + this.user.headImageUrl
      }
      return ''
    }
  },
  methods: {
    ...mapActions([
      'logout'
    ]),
    onCommand(command) {
      switch (command) {
        case 'login':
          this.onLogin()
          break;
        case 'register':
          this.onRegister()
          break;
        case 'my-data':
          this.onMyData()
          break;
        case 'my-reserve':
          this.onMyReserve()
          break;
        case 'setting':
          this.onSetting()
          break;
        case 'logout':
          this.logout({
            isForceUpdate: true
          })
          break;
        default:
          break;
      }
    },
    onLogin() {
      this.$router.push(`/auth?type=login&redirect=${this.$route.path}`)
    },
    onRegister() {
      this.$router.push(`/auth?type=register`)
    },
    onMyData() {
      this.$router.push(`/personal/data`)
    },
    onMyReserve() {
      this.$router.push(`/personal/reserve`)
    },
    onSetting() {
      this.$router.push(`/personal/setting`)
    }
  }
}
</script>

<style lang="less" scoped>
.avatar {
  cursor: pointer;
}
.info {
  padding: 0 20px;
  width: 160px;
  &_name{
    line-height: 40px;
    font-weight: bold;
    font-size: 14px;
  }
}
</style>
