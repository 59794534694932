<template>
  <div
    :class="['section', {
      'section--full-width': fullWidth
    }]"
    :style="sty">
    <div class="section-wrap">
      <div v-if="title" class="section-head">
        <span
          class="title"
          :style="{
            color: titleColor
          }">
          {{ title }}
        </span>
      </div>
      <div class="section-body">
        <slot></slot>
      </div>
      <div v-if="showMore" class="section-foot">
        <div
          :class="['more', {
            'more--border': showMoreBorder
          }]">
          <router-link v-if="moreUrl" :to="moreUrl">更多</router-link>
          <a v-else>更多</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps({
  title: String,
  titleColor: String,
  moreUrl: String,
  showMore: {
    type: Boolean,
    default: true
  },
  showMoreBorder: Boolean,
  fullWidth: Boolean, // 是否全宽 默认最大宽度1200
  backgroundColor: {
    type: String,
    default: 'transparent'
  },
  backgroundImage: String
})
const sty = computed(() => {
  return {
    backgroundColor: props.backgroundColor,
    backgroundImage: props.backgroundImage ? `url(${props.backgroundImage})` : ''
  }
})
</script>

<style lang="less" scoped>
.section {
  padding-top: 60px;
  padding-bottom: 70px;
  width: 100%;
  &-wrap {
    width: 1200px;
    margin: 0 auto;
  }
  &-head {
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      padding: 30px;
      font-weight: 500;
      font-size: 24px;
      color: #000000;
      line-height: 28px;
    }
  }
  &-body {
    padding: 30px 0;
  }
  &-foot {
    display: flex;
    justify-content: center;
    padding: 30px;
    .more {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 66px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        line-height: 21px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        text-decoration: none;
      }
      &--border {
        a {
          border: 1px solid #D9D9D9;
        }
      }
    }
  }
  &--full-width {
    .section-wrap {
      width: 100%;
    }
  }
}
</style>