import setting from '@/setting'

export function tokenSet(token) {
  localStorage.setItem(setting.tokenKey, token)
}
export function tokenGet() {
  let token = localStorage.getItem(setting.tokenKey)
  return token
}
export function userSet(user) {
  localStorage.setItem(setting.userKey, user ? JSON.stringify(user) : '')
}
export function userGet() {
  let user = localStorage.getItem(setting.userKey)
  return user ? JSON.parse(user) : null
}

export default {
  tokenSet,
  tokenGet,
  userSet,
  userGet
}
