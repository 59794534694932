export default {
  lang: {
    zh: '中文',
    en: '英文'
  },
  // 字典相关
  dict: {
    // 性别
    gender: {
      0: '男',
      1: '女'
    },
  },
  // 通用动作配置
  action: {
    add: '新增',
    detail: '详情',
    delete: '删除',
    batchDelete: '批量删除',
    edit: '编辑',
    search: '搜索',
    submit: '提交',
    save: '保存',
    reset: '重置',
    ok: '确定',
    cancel: '取消',
    back: '返回',
    close: '关闭',
    disable: '禁用',
    enable: '启用',
    batchDisabled: '批量禁用',
    batchEnable: '批量启用',
    importData: '导入',
    downloadImportTpl: '下载模板',
    exportData: '导出数据',
    create: '创建',
    update: '更新',
    back: '返回',
    next: '下一步',
    more: '更多',
    download: '下载',
  },
  // 通用日期相关
  date: {
    year: '年',
    month: '月',
    day: '日',
    hour: '时',
    minute: '分',
    second: '秒',
    millisecond: '毫秒',
    january: '1月',
    february: '2月',
    march: '3月',
    april: '4月',
    may: '5月',
    june: '6月',
    july: '7月',
    august: '8月',
    september: '9月',
    october: '10月',
    november: '11月',
    december: '12月',
    monday: '周一',
    tuesday: '周二',
    wednesday: '周三',
    thursday: '周四',
    friday: '周五',
    saturday: '周六',
    sunday: '周日'
  },
  // 通用单词配置（此处配置一下常用的文本单词 如：表格等地方已启用 未启用）
  word: {
    siteName: '岩石地球化学数据中心',
    all: '全部',
    enabled: '已启用',
    disabled: '已禁用',
    canceled: '已取消',
    baseInfo: '基本信息',
    operationLog: '操作日志',
    operationUser: '操作人',
    unknow: '未知',
    operation: '操作',
    keywords: '关键字',
    prevMonth: '上一月',
    curMonth: '当月',
    nextMonth: '下一月',
    range: '区间'
  },
  // 通用信息提示配置
  message: {
    noticeTitle: '提示',
    loginExpire: '未登录或登录过期 请重新登录',
    loginSuccess: '登录成功',
    success: '操作成功',
    failed: '操作失败',
    saveSuccess: '保存成功',
    saveFailed: '保存失败',
    delete: '确定删除该条数据？',
    batchDelete: '确定删除选中{n}条数据？',
    notFound: '请求资源未找到',
    networkError: '网络错误'
  },
  placeholder: {
    input: '请输入'
  },
  // 通用验证提示配置
  validate: {
    number: '必须为数值',
    integer: '必须为整数',
    boolean: '必须为布尔类型',
    date: '必须为日期',
    url: '必须为url',
    email: '必须为email',
    required: '此项为必填项',
    isExist: '已存在相同数据',
    length: '字符串长度要在{min}和{max}之间',
    minLength: '字符串长度最小为{min}',
    maxLength: '字符串长度最大为{max}',
    range: '数值要求在{min}和{max}之间',
    minRange: '数值最小应为{min}',
    maxRange: '数值最大应为{max}',
    specialCode: '不能含有特殊字符',
    fileFormatAllow: '只允许{format}类型',
    fileSizeLimit: '文件大小最大限制为{size}',
    fileNumberLimit: '文件上传个数最多为{num}个',
    treeMaxLevel: '最大层级不能超过{level}层'
  },

  // 公共实体类
  model: {
    user: {
      UserTrueName: '姓名',
      Gender: '性别',
      Address: '地址',
      PhoneNo: '手机',
      Email: '邮箱'
    },
    sample: {
      Sample_Name: '样品编号',
      Continent_or_Ocean: '大陆或大洋',
      Country: '国家',
      Terrane: '地体',
      Rock_or_stratigraphic_unit: '岩石或地层单元',
      Object_or_mineral_determined: '测定对象或矿物',
      Geodynamic_settings: '地球动力学背景',
      Geological_period: '地质时代',
      Min_age_limit: '地质时代最小值',
      Max_age_limit: '地质时代最大值',
      Lithology: '岩性大类',
      Metamorphic_grade: '变质级别',
      Reference_type: '文献类型',
      Language: '语言',
      Journal: '刊物名',
      Title: '题名',
      First_Author: '第一作者名',
      Year: '出版年',
      Place_for_experiment: '测试机构',
      Instrument_for_analysis: '测试仪器',
      Analytical_content: '测试内容',
      Expression: '表达方式',
      Interpretation: '年龄解释',
      Age: '年龄值',
      SE2: '误差2SE(Ma)',
      Longitude: '经度',
      Latitude: '纬度',
      Detailed_locality: '具体位置',
      Detailed_localityck: '参考位置',
      Elevation: '海拔',
      Drilling_name: '钻孔名称',
      Drilling_depth: '钻孔深度',
      Rock_forming_minerals: '造岩矿物',
      Lithology_subdivision: '岩性小类',
      Sedimentary_environment: '沉积环境',
      DOI_or_URL: 'DOI或URL',
      Volume: '卷',
      Issue: '期',
      Pages: '页',
      Article_Number: '文章号',
      Object_or_mineral_determined: '测定对象或矿物',
    }
  },

  // 账户相关
  auth: {
    message: {
      unLogin: '未登录'
    },
    action: {
      login: '登录',
      logout: '退出',
      forgetPwd: '忘记密码',
      register: '注册',
      setting: '设置',
      data: '我的数据',
      reserve: '我的预约',
      applyRestPassword: '申请重置密码',
      next: '下一步',
      submit: '提交',
      sendVerCode: '发送验证码'
    },
    m_login: {
      name: '登录',
      account: '账号',
      password: '密码',
      verificationCode: '验证码',
      register: '注册',
      noAccount: '没有账号？',
    },
    m_findPassword: {
      name: '重置密码',
      inputRegisterEmail: '请填写您注册的Email'
    },
    m_register: {
      name: '注册',
      inputEmail: '请填写您的Email',
      account: '账号',
      alreadyHasAccount: '已有账号？',
      pleaseClickLinkValidateEmail: '请点击链接完成邮箱验证',
      verificationCode: '请输入验证码',
      inputPassword: '请输入密码',
      inputPasswordRepeat: '请重复输入密码',
      alreadyReadAndAgree: '我已阅读并同意',
      useAgreement: '使用条款',
    },
  },
  // 页面
  page: {
    home: {
      name: '首页'
    },
    search: {
      name: '数据检索',
      m_tab: {
        composition: '地球化学成分',
        keyword: '关键字检索',
        visualized: '数据检索可视化',
        topic: '专题检索',
      },
      child_kw: {
        m_tab: {
          sample: '样品信息',
          reference: '参考文献',
          testMethod: '分析方法',
          ageResult: '年龄结果'
        }
      }
    },
    sample: {
      name: '数据录入',
      m_tab: {
        add: '逐篇录入',
        importNonStandard: '用户数据自适应匹配录入',
        importStandard: '标准数据模板录入'
      }
    },
    visualized: {
      name: '数据检索可视化'
    },
    reserve: {
      name: '实验预约'
    },
    journal: {
      name: '期刊专题'
    },
    news: {
      name: '新闻动态'
    },
    aboutus: {
      name: '关于我们',
      m_tab: {
        intro: '概况简介',
        concat: '联系我们'
      }
    },
    result: {
      name: '结果',
      action: {
        mark: '地图标注'
      }
    },
    detail: {
      name: '详情',
      m_tab: {
        base: '基础信息',
        micronutrient: '微量元素分析',
        principalElement: '主元素分析',
        otopes: '同位素分析',
        ICPMS: '定年分析'
      }
    },
    personal: {
      name: '个人中心',
      child_setting: {
        changeAvatar: '修改头像'
      }
    }
  },

  // 模块-底部
  m_foot: {
    address: '地址',
    addressVal: '北京市朝阳区北土城西路19号',
    postalCode: '邮编',
    tel: '电话',
    fax: '传真',
  }


}
