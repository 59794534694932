import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhCN from './zh-CN'
import enUS from './en-US'
import elEnLocale from 'element-ui/lib/locale/lang/en'
import elZhLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(VueI18n);

Vue.locale = () => {};

let lang = localStorage.getItem('lang') || 'zh-CN';

const i18n = new VueI18n({
  locale: lang,
  messages: {
    'zh-CN': Object.assign(zhCN, elZhLocale),
    "en-US": Object.assign(enUS, elEnLocale)
  }
});

export default i18n;
