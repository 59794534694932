export default {
  /** 通用级别配置 */
  lang: {
    zh: 'chinese',
    en: 'english'
  },
  // 字典相关
  dict: {
    // 性别
    gender: {
      0: 'male',
      1: 'female'
    },
  },
  // 通用动作配置
  action: {
    add: 'add',
    detail: 'detail',
    delete: 'delete',
    batchDelete: 'batchDelete',
    edit: 'edit',
    search: 'search',
    submit: 'submit',
    save: 'save',
    reset: 'reset',
    ok: 'ok',
    cancel: 'cancel',
    back: 'back',
    close: 'close',
    disable: 'disable',
    enable: 'enable',
    batchDisable: 'batchDisable',
    batchEnable: 'batchEnable',
    importData: 'importData',
    downloadImportTpl: 'downloadTemplate',
    exportData: 'exportData',
    create: 'create',
    update: 'update',
    back: 'back',
    more: 'more',
    download: 'download',
  },
  // 通用日期相关
  date: {
    year: 'year',
    month: 'month',
    day: 'day',
    hour: 'hour',
    minute: 'minute',
    second: 'second',
    millisecond: 'millisecond',
    january: 'january',
    february: 'february',
    march: 'march',
    april: 'april',
    may: 'may',
    june: 'june',
    july: 'july',
    august: 'august',
    september: 'september',
    october: 'october',
    november: 'november',
    december: 'december',
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
    sunday: 'sunday'
  },
  // 通用单词配置（此处配置一下常用的文本单词 如：表格等地方已启用 未启用）
  word: {
    siteName: 'INSTITUTE OF GEOLOGY AND GEOPHYSICS CHINESE ACADEMY OF SCIENCES',
    all: 'all',
    enabled: 'enabled',
    disabled: 'disabled',
    canceled: 'canceled',
    baseInfo: 'base info',
    operationLog: 'operation log',
    operationUser: 'operation user',
    unknow: 'unknow',
    operation: 'operation',
    keywords: 'keywords',
    prevMonth: 'prev month',
    curMonth: 'current month',
    nextMonth: 'next month',
    range: 'range'
  },
  // 通用信息提示配置
  message: {
    noticeTitle: 'notice',
    loginExpire: 'unlogin or login expire, please login',
    loginSuccess: 'login success',
    success: 'success',
    failed: 'failed',
    saveSuccess: 'save success',
    saveFailed: 'save failed',
    delete: 'are you sure delete？',
    batchDelete: 'are you sure delete {n} row？',
    notFound: 'not found',
    networkError: 'network error'
  },
  placeholder: {
    input: 'please input'
  },
  // 通用验证提示配置
  validate: {
    number: 'only allow number',
    integer: 'only allow integer',
    boolean: 'only allow bool',
    date: 'only allow date',
    url: 'only allow url',
    email: 'only allow email',
    required: 'required',
    isExist: 'has already exist',
    length: 'string range between {min} and {max}',
    minLength: 'string min length is {min}',
    maxLength: 'string max length is {max}',
    range: 'number should between {min} and {max}',
    minRange: 'min number is {min}',
    maxRange: 'max number is {max}',
    specialCode: 'not allow special code',
    fileFormatAllow: 'only allow {format} file type',
    fileSizeLimit: 'max limit size: {size}',
    fileNumberLimit: 'max limit {num} file',
    treeMaxLevel: 'max level limit {level}'
  },

  // 公共实体类
  model: {
    user: {
      UserTrueName: 'user true name',
      Gender: 'gender',
      Address: 'address',
      PhoneNo: 'phone',
      Email: 'email'
    },
    sample: {
      Sample_Name: 'sample name',
      Continent_or_Ocean: 'continent or ocean',
      Country: 'country',
      Terrane: 'terrane',
      Rock_or_stratigraphic_unit: 'rock or stratigraphic unit',
      Object_or_mineral_determined: 'object or mineral determined',
      Geodynamic_settings: 'geodynamic settings',
      Geological_period: 'geological period',
      Min_age_limit: 'min age limit',
      Max_age_limit: 'max age limit',
      Lithology: 'lithology',
      Metamorphic_grade: 'metamorphic grade',
      Reference_type: 'reference type',
      Language: 'language',
      Journal: 'journal',
      Title: 'title',
      First_Author: 'first author',
      Year: 'year',
      Place_for_experiment: 'place for experiment',
      Instrument_for_analysis: 'instrument for analysis',
      Analytical_content: 'analytical content',
      Expression: 'expression',
      Interpretation: 'interpretation',
      Age: 'age',
      SE2: 'SE2',
      Longitude: 'longitude',
      Latitude: 'latitude',
      Detailed_locality: 'detailed locality',
      Elevation: 'elevation',
      Drilling_name: 'drilling name',
      Drilling_depth: 'drilling depth',
      Rock_forming_minerals: 'rock forming minerals',
      Lithology_subdivision: 'lithology subdivision',
      Sedimentary_environment: 'sedimentary environment',
      DOI_or_URL: 'DOI or URL',
      Volume: 'volume',
      Issue: 'issue',
      Pages: 'pages',
      Article_Number: 'article number',
      Object_or_mineral_determined: 'object or mineral determined',
    }
  },

  // 账户相关
  auth: {
    message: {
      unLogin: 'Not Logged In'
    },
    action: {
      login: 'login',
      logout: 'logout',
      forgetPwd: 'forget password',
      register: 'register',
      setting: 'setting',
      data: 'my data',
      reserve: 'my reverse',
      applyRestPassword: 'apply rest password',
      next: 'next',
      submit: 'submit',
      sendVerCode: 'send verification code'
    },
    m_login: {
      name: 'LOGIN',
      account: 'account',
      password: 'password',
      verificationCode: 'verification code',
      register: 'register',
      noAccount: 'no account number?',
    },
    m_findPassword: {
      name: 'RESET PASSWORD',
      inputRegisterEmail: 'please input register email'
    },
    m_register: {
      name: 'REGISTER',
      inputEmail: 'please input your email',
      account: 'accout',
      alreadyHasAccount: 'already has account?',
      pleaseClickLinkValidateEmail: 'please click link validate email',
      verificationCode: 'verification code',
      inputPassword: 'please input password',
      inputPasswordRepeat: 'please input password repeat',
      alreadyReadAndAgree: 'already read and agree',
      useAgreement: 'use agreement',
    },
  },
  // 页面
  page: {
    home: {
      name: 'home'
    },
    search: {
      name: 'search',
      m_tab: {
        composition: 'earth composition',
        keyword: 'keyword query',
        visualized: 'visualized',
        topic: 'topic query',
      },
      child_kw: {
        m_tab: {
          sample: 'sample',
          reference: 'reference',
          testMethod: 'test method',
          ageResult: 'age result'
        }
      }
    },
    sample: {
      name: 'data input',
      m_tab: {
        add: 'standard data input',
        importNonStandard: 'nonstandard data input',
        importStandard: 'standard data input'
      }
    },
    visualized: {
      name: 'visualized'
    },
    reserve: {
      name: 'reserve'
    },
    journal: {
      name: 'journal'
    },
    news: {
      name: 'news'
    },
    aboutus: {
      name: 'about us',
      m_tab: {
        intro: 'intro',
        concat: 'concat us'
      }
    },
    result: {
      name: 'result',
      action: {
        mark: 'mark'
      }
    },
    detail: {
      name: 'detail',
      m_tab: {
        base: 'base info',
        micronutrient: 'micronutrient',
        principalElement: 'principal element',
        otopes: 'otopes',
        ICPMS: 'ICPMS'
      }
    },
    personal: {
      name: 'personal',
      child_setting: {
        changeAvatar: 'change avatar'
      }
    }
  },
  // 模块-底部
  m_foot: {
    address: 'address',
    addressVal: 'No. 19 Beitucheng West Road, Chaoyang District, Beijing 100029, China',
    postalCode: 'postal code',
    tel: 'tel',
    fax: 'fax',
  }
}
