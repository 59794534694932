<template>
  <div :class="['page-cover', `page-cover--img-${code}`]" :style="style">
    <div class="page-cover_layer" :style="layerStyle"></div>
    <div class="page-cover_content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    code: {
      type: String,
      default: '1'
    },
    height: {
      type: Number,
      default: 500
    },
    layer: {
      type: String,
      default: 'rgba(0,0,0,0)'
    }
  },
  computed: {
    style() {
      return {
        'height': `${this.height}px`
      }
    },
    layerStyle() {
      return {
        'background-color': this.layer
      }
    }
  }
}
</script>

<style lang="less" scoped>
.page-cover {
  position: relative;
  background-position: center;
  background-size: cover;
  background-image: url('./images/1.jpg');
  &_layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &_content {
    position: relative;
    z-index: 10;
    margin: 0 auto;
    width: 1200px;
    height: 100%;
  }
  &--img-1 {
    background-image: url('./images/1.jpg');
  }
  &--img-2 {
    background-image: url('./images/2.jpg');
  }
  &--img-3 {
    background-image: url('./images/3.jpg');
  }
  &--img-4 {
    background-image: url('./images/4.jpg');
  }
  &--img-5 {
    background-image: url('./images/5.jpg');
  }
  &--img-6 {
    background-image: url('./images/6.jpg');
  }
  &--img-7 {
    background-image: url('./images/7.jpg');
  }
  &--img-8 {
    background-image: url('./images/8.jpg');
  }
  &--img-9 {
    background-image: url('./images/9.jpg');
  }
  &--img-10 {
    background-image: url('./images/10.jpg');
  }
  &--img-11 {
    background-image: url('./images/11.jpg');
  }
  &--img-12 {
    background-image: url('./images/12.jpg');
  }
  &--img-13 {
    background-image: url('./images/13.jpg');
  }
  &--img-14 {
    background-image: url('./images/14.jpg');
  }
  &--img-15 {
    background-image: url('./images/15.jpg');
  }
  &--img-16 {
    background-image: url('./images/16.jpg');
  }
  &--img-17 {
    background-image: url('./images/17.jpg');
  }
  &--img-18 {
    background-image: url('./images/18.jpg');
  }
}
</style>
